import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        art_fast: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "fast" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_learn: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "learn_yourself" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_ideas: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "ideas" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="about">
        <Container>
          <Grid>
            <div>
              <h2>Know Your Insurances </h2>
              <p>
                Learn about some of the different types of insurance options
                available to you.
              </p>
            </div>
            <Art>
              <Img fluid={data.art_fast.childImageSharp.fluid} />
            </Art>
          </Grid>
          <Grid inverse>
            <Art>
              <Img fluid={data.art_learn.childImageSharp.fluid} />
            </Art>
            <div>
              <h3> Trauma Insurance</h3>
              <br />
              <p>
                {' '}
                Trauma insurance, also called ‘critical illness’ or ‘recovery
                insurance’ pays a lump sum amount if you suffer a critical
                illness or serious injury. This includes cancer, a heart
                condition, major head injury or stroke.
              </p>
            </div>
          </Grid>
          <Grid>
            <div>
              <h3>Income Protection Insurance</h3>
              <p>
                Income protection is typically arranged where 75% of your
                pre-tax income is recovered via insurance if you cannot work due
                to partial or total disability. This will help you and your
                family continue staying on top of your expenses as you recover.
                <br />
                <br />
              </p>
            </div>
            <Art>
              <Img fluid={data.art_ideas.childImageSharp.fluid} />
            </Art>
          </Grid>

          <Grid inverse>
            <Art>
              <Img fluid={data.art_learn.childImageSharp.fluid} />
            </Art>
            <div>
              <h3>Life Insurance</h3>
              <p>
                Life insurance is beneficial to those around you. In the event
                of your death, the insurance company will payout a lump sum to
                your beneficiaries. If you have dependents, life insurance may
                be something that should be seriously considered.
              </p>
              <br />
            </div>
          </Grid>
          <Grid>
            <div>
              <h3>TPD Insurance (Total and Permanent Disability)</h3>
              <p>
                Total and permanent disability insurance will payout a lump sum
                if you become ‘totally and permanently disabled’ because of
                injury or illness.
                <ul>
                  Own occupation TPD
                  <li>
                    You cannot ever work in your current job after you are
                    disabled
                  </li>
                  Any occupation TPD
                  <li>
                    You cannot ever work in any job suited to your education,
                    training or experience
                  </li>
                </ul>
                <br />
                <br />
              </p>
            </div>
            <Art>
              <Img fluid={data.art_ideas.childImageSharp.fluid} />
            </Art>
          </Grid>
        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default About;
